import altair_icon from 'Assets/images/altair.svg';
import apron_icon from 'Assets/images/apron-rococo.png';
import ares_icon from 'Assets/images/ares.svg';
import basilisk_icon from 'Assets/images/basilisk.png';
import bifrost_icon from 'Assets/images/bifrost-rococo.svg';
import bitcountry_icon from 'Assets/images/bitcountry-pioneer.png';
import chainx_icon from 'Assets/images/chainx-rococo.svg';
import calamari_icon from 'Assets/images/calamari.png';
import crab_icon from 'Assets/images/crab-rococo.svg';
import crust_icon from 'Assets/images/crust.png';
import crust_shadow_icon from 'Assets/images/crust-shadow.svg';
import darwinia_icon from 'Assets/images/darwinia.png';
import encointer_notee_icon from 'Assets/images/encointer-notee-rococo.svg';
import genshiro_icon from 'Assets/images/genshiro.svg';
import integritee_icon from 'Assets/images/integritee.png';
import karura_icon from 'Assets/images/karura.svg';
import khala_icon from 'Assets/images/khala.svg';
import kintsugi_icon from 'Assets/images/kintsugi.png';
import kilt_icon from 'Assets/images/kilt.png';
import loom_icon from 'Assets/images/loom_network.png';
import moonriver_icon from 'Assets/images/moonriver.png';
import parallel_icon from 'Assets/images/heiko.svg';
import picasso_icon from 'Assets/images/picasso.svg';
import polkasmith_icon from 'Assets/images/polkasmith.svg';
import quartz_icon from 'Assets/images/quartz.png';
import sakura_icon from 'Assets/images/sakura.svg';
import robonomics_icon from 'Assets/images/robonomics-rococo.svg';
import shiden_icon from 'Assets/images/shiden-rococo.png';
import statemint_icon from 'Assets/images/statemine.svg';
import subgame_icon from 'Assets/images/subgame.png';
import trustbase_icon from 'Assets/images/trustbase-rococo.png';
export default [{
  name: 'networkList',
  value: {
    all: {
      value: [{
          label: "Polkadot",
          network: "polkadot",
          value: "polkadot",
          type: "mainnet",
        },
        {
          label: "Kusama",
          network: "kusama",
          value: "kusama",
          type: "mainnet",
        }
      ]
    },
    donate_public_key: {
      value: "0x976ce4203c844a11164d1b3f8342ad16a4cc5d99ac8eaae5cd74f4b1cc68c764"
    },
    ss58Format: {
      value: {
        polkadot: {
          prefix: 0,
          network: 'polkadot',
          hasLink: true
        },
        kusama: {
          prefix: 2,
          network: 'kusama',
          hasLink: true
        },
      }
    },
    // https://github.com/polkadot-js/apps/blob/master/packages/apps-config/src/endpoints/testingRelayRococo.ts
    parachains: {
      kusama: [{
          info: 'statemine',
          paraId: 1000,
          text: 'Statemine',
          logo: statemint_icon,
          subscan: "https://statemine.subscan.io/",
        },
        {
          info: 'altair',
          paraId: 2088,
          text: 'Altair',
          logo: altair_icon,
        },
        {
          info: 'basilisk',
          paraId: 2090,
          text: 'Basilisk',
          logo: basilisk_icon,
        },
        {
          info: 'bifrost',
          paraId: 2001,
          text: 'Bifrost',
          logo: bifrost_icon,
          subscan: "https://bifrost.subscan.io/",
          pr: "https://github.com/itering/parachain-projects-info/pull/15",
          "Description (en)": "Bifrost is the Polkadot Ecological DeFi basic protocol. It is committed to becoming an infrastructure for pledged assets to provide liquidity. Bifrost launched derivatives vToken for Staking and Polkadot Parachain Slot (Crowdloan). It has obtained $2.15M in fund-raising from NGC, SNZ, DFG, CMS and other institutions and Web3 Foundation Grant. It is also a member of Substrate Builders Program and Web3 Bootcamp.",
          "Description (zh)": "Bifrost（彩虹桥）是波卡生态 DeFi 基础协议，致力成为质押资产提供流动性的基础设施，目前推出面向 Staking 和波卡平行链卡槽（Crowdloan）的衍生品 vToken。目前已获得 NGC、SNZ、DFG、CMS 等机构数百万美金融资和 Web3 基金会 Grant，同时也是 Substrate Builders Program、Web3 Bootcamp 成员。",
          "Parachain Crowdloans Allocation (en)": "https://medium.com/bifrost-finance/bifrost-kusama-auction-is-now-officially-opened-7e3b555ba212",
          "Parachain Crowdloans Allocation (zh)": "https://mp.weixin.qq.com/s/5ixSwtVdtIOfgCTFpGeWfA",
          "Auction Reward & Reward Vesting Schedule (en)": "https://bifrost-finance.medium.com/reward-climbs-to-200-bifrost-launches-parachain-auction-sprint-program-2326b3b314c5",
          "Auction Reward & Reward Vesting Schedule (zh)": "https://bifrost-finance.medium.com/reward-climbs-to-200-bifrost-launches-parachain-auction-sprint-program-2326b3b314c5",
          "Financing Information (en)": "https://wiki.bifrost.finance/publicity/token",
          "Financing Information (zh)": "https://wiki.bifrost.finance/v/zh/publicity/token",
          "Website Link": "https://bifrost.finance/",
          "Twitter Link": "https://twitter.com/bifrost_finance",
          "Telegram Link": "https://t.me/bifrost_finance",
          "Discord Link": "https://discord.gg/8DRBw2h5X4",
          "Medium Link": "https://medium.com/@bifrost_finance",
          "Github Link": "https://github.com/bifrost-finance",
        },
        {
          info: 'Bit.Country Pioneer',
          paraId: 2096,
          text: 'Bit.Country Pioneer',
          logo: bitcountry_icon,
          pr: "https://github.com/itering/parachain-projects-info/pull/20",
          "Description (en)": "Bit.Country Metaverse Network is an application framework and blockchain for user-created metaverses and games. The Platform for User-created Metaverses & Games with Opportunities to Earn.",
          "Parachain Crowdloans Allocation (en)": "https://bitcountry.medium.com/bit-country-pioneer-crowdloan-on-kusama-2acb5cde9f85",
          "Parachain Crowdloans Allocation (zh)": "https://bitcountry.medium.com/bit-country-pioneer-crowdloan-on-kusama-2acb5cde9f85",
          "Auction Reward & Reward Vesting Schedule (en)": "https://bitcountry.medium.com/bit-country-pioneer-crowdloan-on-kusama-2acb5cde9f85",
          "Auction Reward & Reward Vesting Schedule (zh)": "https://bitcountry.medium.com/bit-country-pioneer-crowdloan-on-kusama-2acb5cde9f85",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "http://bit.country/",
          "Twitter Link": "https://twitter.com/bitdotcountry",
          "Telegram Link": "https://t.me/BitCountryOfficialTG",
          "Discord Link": "https://discord.gg/PaMAXZZ59N",
          "Medium Link": "https://bitcountry.medium.com/",
          "Github Link": "https://github.com/bit-country/Metaverse-Network",
          "Owner account": "Ff9JGfDpZ9TjLh7C2NXSUNAd3rAFNFqefxWE35vjCFyXKr4"
        },
        {
          info: 'calamari',
          paraId: 2084,
          text: 'Calamari',
          logo: calamari_icon,
          subscan: "https://calamari.subscan.io/",
          pr: "https://github.com/itering/parachain-projects-info/pull/17",
          "Description (en)": "Calamari, Manta Network's canary-net, is the plug-and-play privacy-preservation parachain built to service the Kusama DeFi world. It combines Kusama and zkSNARKs to bring on-chain privacy to transactions and swaps.",
          "Description (zh)": "Calamari Network 作为 Manta Network 的社区化先行网，是在 Kusama 上即插即用的隐私保护平行链，旨在为整个 Kusama 的 DeFi 网络提供隐私服务。 Calamari Network 建立在 Kusama 上作为平行链保证跨链互操作性，基于 zkSNARK （零知识证明技术）保障隐私性。",
          "Parachain Crowdloans Allocation (en)": "",
          "Parachain Crowdloans Allocation (zh)": "",
          "Auction Reward & Reward Vesting Schedule (en)": "",
          "Auction Reward & Reward Vesting Schedule (zh)": "",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "www.calamari.network",
          "Twitter Link": "twitter.com/calamarinetwork",
          "Telegram Link": "t.me/mantanetworkofficial",
          "Discord Link": "https://discord.gg/G9zwBRW7Ad",
          "Medium Link": "mantanetwork.medium.com",
          "Github Link": "github.com/manta-network",
        },
        {
          info: 'shadow',
          paraId: 2012,
          text: 'Crust Shadow',
          logo: crust_shadow_icon,
          pr: "https://github.com/itering/parachain-projects-info/pull/5",
          "Description (en)": "Crust Shadow is a `Kusama version` for Crust Network, an early unaudited release of the code that is available first and holds real economic value. Crust Shadow is designed to be a Kusama parachain, and will participate in Kusama's slot auctions. Crust Shadow is a proving ground for XCMP, and can provide storage functions to other parachains. Crust Shadow will be upgraded with the development of Kusama.",
          "Description (zh)": "Crust Shadow是一个 “KUSAMA版本” 的Crust网络, 一个早期未经审核发布的可用代码, 并拥有真正的经济价值。Crust Shadow被设计成Kusama平行链。它将参加Kusama的平行链插槽拍卖。Crust Shadow是XCMP的一个试验场, 可以为其他平行链提供存储功能, 将随着Kusama的发展而升级。",
          "Parachain Crowdloans Allocation (en)": "https://medium.com/crustnetwork/kusama-parachain-auction-plan-crust-network-5335996621f6",
          "Parachain Crowdloans Allocation (zh)": "https://medium.com/crustnetwork/kusama-parachain-auction-plan-crust-network-5335996621f6",
          "Auction Reward & Reward Vesting Schedule (en)": "https://medium.com/crustnetwork/join-the-crust-shadow-crowdloan-for-the-kusama-parachain-slot-auction-e0f041ae9627",
          "Auction Reward & Reward Vesting Schedule (zh)": "https://medium.com/crustnetwork/join-the-crust-shadow-crowdloan-for-the-kusama-parachain-slot-auction-e0f041ae9627",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://crust.network/",
          "Twitter Link": "https://twitter.com/clover_finance/",
          "Telegram Link": "https://t.me/CrustNetwork",
          "Discord Link": "https://discord.gg/Jbw2PAUSCR",
          "Medium Link": "https://crustnetwork.medium.com/",
          "Github Link": "https://github.com/crustio",
        },
        {
          info: 'crab',
          paraId: 2006,
          text: 'Darwinia Crab Redirect',
          logo: crab_icon,
          subscan: "https://crab.subscan.io/",
          pr: "https://github.com/itering/parachain-projects-info/pull/14",
          "Description (en)": "Crab is a canary network with real economics for Darwinia",
          "Description (zh)": "Crab 网络是 Darwinia 网络的一个有经济价值的金丝雀网络",
          "Parachain Crowdloans Allocation (en)": "https://darwinianetwork.medium.com/darwinia-crabs-kusama-parachain-auction-strategy-3f37cbfdfe4",
          "Parachain Crowdloans Allocation (zh)": "https://mp.weixin.qq.com/s/fvQIiQp0xqkgXY4bG8Mskw",
          "Auction Reward & Reward Vesting Schedule (en)": "https://darwinianetwork.medium.com/darwinia-crabs-kusama-parachain-auction-strategy-3f37cbfdfe4",
          "Auction Reward & Reward Vesting Schedule (zh)": "https://mp.weixin.qq.com/s/fvQIiQp0xqkgXY4bG8Mskw",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://crab.network/",
          "Twitter Link": "https://twitter.com/DarwiniaNetwork",
          "Telegram Link": "https://t.me/DarwiniaNetwork",
          "Discord Link": "https://discord.gg/KSJGA29Xz5",
          "Medium Link": "https://medium.com/@DarwiniaNetwork",
          "Github Link": "https://github.com/darwinia-network",
        },
        {
          info: 'encointer_canary',
          paraId: 2014,
          text: 'Encointer Canary',
          logo: encointer_notee_icon,
        },
        {
          info: 'genshiro',
          paraId: 2024,
          text: 'Genshiro',
          logo: genshiro_icon,
          pr: "https://github.com/itering/parachain-projects-info/pull/19",
          "Description (en)": "Genshiro is a cross-chain DeFi one-stop shop on Kusama with novel liquidation mechanics and portfolio margining. With bailouts, no auctions are needed, while liquidity providers (bailsmen) are supplying liquidity in advance. Our platform leverages unique on-chain risk modeling to enable the most demanded use cases like borrowing, pooled lending, stablecoin generation, and decentralized trading on margin with up to 20x leverage. Genshiro works with portfolios rather than single collateral vaults to minimize risks for users. We bring all known DeFi instruments to work together in a beautiful and unified way.",
          "Description (zh)": "Genshiro是Kusama上第一个一站式一体化的DeFi商店，拥有新颖的清算机制和投资抵押。在救助机制中，不需要拍卖，就能提供很好的流动性。我们使用链上风险建模，允许平台借款、借贷、生成稳定币，以及去中心化的融资融券交易和高达20倍的杠杆。Genshiro使用的投资组合不是单一的担保金来最大限度地降低用户的风险。现Genshiro能完成DeFi上的所有功能。",
          "Parachain Crowdloans Allocation (en)": "https://genshiro.equilibrium.io/en/plo",
          "Parachain Crowdloans Allocation (zh)": "https://genshiro.equilibrium.io/zh/plo",
          "Auction Reward & Reward Vesting Schedule (en)": "https://genshiro.equilibrium.io/en/plo",
          "Auction Reward & Reward Vesting Schedule (zh)": "https://genshiro.equilibrium.io/zh/plo",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://genshiro.equilibrium.io/",
          "Twitter Link": "https://twitter.com/GenshiroDeFi",
          "Telegram Link": "https://t.me/genshiro_official",
          "Discord Link": "https://discord.gg/p2FmYuW5Hu",
          "Medium Link": "https://medium.com/equilibrium-eosdt",
          "Github Link": "https://github.com/equilibrium-eosdt",
        },
        {
          info: 'integritee',
          paraId: 2015,
          text: 'IntegriTEE Network',
          logo: integritee_icon,
        },
        {
          info: 'karura',
          paraId: 2000,
          text: 'Karura',
          logo: karura_icon,
          subscan: "https://karura.subscan.io/",
          pr: "https://github.com/itering/parachain-projects-info/pull/9",
          "Description (en)": "Karura is the all-in-one DeFi Hub of Kusama and Acala’s sister network. Karura provides a platform for DApps as well as a suite of DeFi applications including liquid KSM staking, the Karura DEX, and the Karura Dollar (kUSD) stablecoin.",
          "Description (zh)": "Karura 是 Kusama 一站式跨链 DeFi 平台，Karura 能够为用户带来快速的资产互换体验，稳健的去中心化资产借贷体验，丰富的流动性挖矿体验以及安全的释放 KSM Staking 流动性体验，以及发行和使用稳定币 Karura Dollar (kUSD)",
          "Parachain Crowdloans Allocation (en)": "11% of KAR supply. https://acala.network/karura/token",
          "Parachain Crowdloans Allocation (zh)": "KAR 总发行量的11%. https://acala.network/karura/token",
          "Auction Reward & Reward Vesting Schedule (en)": "30% liquid upon launch and 70% linear vesting over the duration of the parachain slot. https://acala.network/karura/token",
          "Auction Reward & Reward Vesting Schedule (zh)": "30%上线后即刻流动，70%在租赁期间线性释放. https://acala.network/karura/token",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://karura.network",
          "Twitter Link": "https://twitter.com/KaruraNetwork",
          "Telegram Link": "https://t.me/karuranetwork",
          "Discord Link": "https://discord.gg/HpsZx5r",
          "Medium Link": "https://medium.com/acalanetwork",
          "Github Link": "https://github.com/AcalaNetwork/"
        },
        {
          info: 'khala',
          paraId: 2004,
          text: 'Khala Network',
          logo: khala_icon,
          subscan: "https://khala.subscan.io/",
          pr: "https://github.com/itering/parachain-projects-info/pull/11",
          "Description (en)": "Khala Network is the Phala pre-mainnet on Kusama, as published on the roadmap last year: Phala will implement its mainnet on Polkadot, as the parachain to serve enterprise-scale blockchains and DeFi service. Khala will implement its mainnet on Kusama, as the parachain to serve creative and growth blockchains and DeFi service.",
          "Description (zh)": "根据去年发布的发展愿景，Khala Network 现已发展成为了 Kusama 的 Phala 预主网：Phala 将部署其主网至 Polkadot，作为服务于企业级区块链和 DeFi 服务的平行链。Khala 将部署其主网至 Kusama，作为服务于创新型和增长型区块链和 DeFi 服务的平行链。",
          "Parachain Crowdloans Allocation (en)": "https://medium.com/phala-network/even-more-rewards-coming-up-to-150-pha-per-ksm-and-new-special-edition-nfts-2598f96f555a",
          "Parachain Crowdloans Allocation (zh)": "https://mp.weixin.qq.com/s/_aD43bKu4lAaJM6FiUbgvA",
          "Auction Reward & Reward Vesting Schedule (en)": "https://medium.com/phala-network/phalas-kusama-slot-auction-strategy-34cb93e07f3a",
          "Auction Reward & Reward Vesting Schedule (zh)": "https://mp.weixin.qq.com/s/1iaLE_8Q2LjlgUsxW6LtWA",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://phala.network/en/khala",
          "Twitter Link": "https://twitter.com/PhalaNetwork",
          "Telegram Link": "https://t.me/phalanetwork",
          "Discord Link": "https://discord.gg/myBmQu5",
          "Medium Link": "https://medium.com/phala-network",
          "Github Link": "https://github.com/Phala-Network",
          "Owner account": "DaEJPYPCJQnKeHGfV6SSF8WPWtLg9zggbWwAwCZRWVPeWvv"
        },
        {
          info: 'kilt',
          paraId: 2086,
          text: 'KILT Protocol',
          logo: kilt_icon,
          subscan: "https://spiritnet.subscan.io/",
          pr: "https://github.com/itering/parachain-projects-info/pull/16",
          "Description (en)": "KILT is a blockchain protocol for issuing self-sovereign verifiable, revocable credentials in the Web 3.0.",
          "Parachain Crowdloans Allocation (en)": "",
          "Parachain Crowdloans Allocation (zh)": "",
          "Auction Reward & Reward Vesting Schedule (en)": "",
          "Auction Reward & Reward Vesting Schedule (zh)": "",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://www.kilt.io/",
          "Twitter Link": "https://twitter.com/Kiltprotocol",
          "Telegram Link": "https://t.me/KILTProtocolChat",
          "Discord Link": "",
          "Medium Link": "https://kilt-protocol.medium.com/",
          "Github Link": "https://github.com/KILTprotocol",
          "Owner account": "DM5jFFrjW6TQWBBz7UAL7GssDVP94wGhhwLcJnHiY1EFfCY"
        },
        {
          info: 'kintsugi',
          paraId: 2092,
          text: 'Kintsugi BTC',
          logo: kintsugi_icon,
        },
        {
          info: 'kpron',
          paraId: 2019,
          text: 'Kpron',
          logo: apron_icon,
        },
        {
          info: 'loomNetwork',
          paraId: 2080,
          text: 'Loom Network',
          logo: loom_icon,
        },
        {
          info: 'mars',
          paraId: 2008,
          text: 'Mars',
          logo: ares_icon,
        },
        {
          info: 'moonriver',
          paraId: 2023,
          text: 'Moonriver',
          logo: moonriver_icon,
          subscan: "https://moonriver.subscan.io/",
        },
        {
          info: 'heiko',
          paraId: 2085,
          text: 'Parallel Heiko',
          logo: parallel_icon,
          pr: "https://github.com/itering/parachain-projects-info/pull/18",
          "Description (en)": "A decentralized lending & staking protocol built on top of the Polkadot ecosystem.",
          "Description (zh)": "Parallel Heiko作为Parallel在kusama上的先行网，提供去中心化的借贷，质押功能",
          "Parachain Crowdloans Allocation (en)": "",
          "Parachain Crowdloans Allocation (zh)": "",
          "Auction Reward & Reward Vesting Schedule (en)": "",
          "Auction Reward & Reward Vesting Schedule (zh)": "",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://parallel.fi",
          "Twitter Link": "https://twitter.com/ParallelFi",
          "Telegram Link": "https://t.me/parallelfi",
          "Discord Link": "https://discord.com/invite/buKKx4dySW",
          "Medium Link": "https://parallelfinance.medium.com",
          "Github Link": "https://github.com/parallel-finance",
        },
        {
          info: 'picasso',
          paraId: 2087,
          text: 'Picasso',
          logo: picasso_icon,
        },
        {
          info: 'polkasmith',
          paraId: 2009,
          text: 'PolkaSmith',
          logo: polkasmith_icon,
          pr: "https://github.com/itering/parachain-projects-info/pull/10",
          "Description (en)": "Implemented on Kusama Network, PolkaSmith is a canary chain of PolkaFoundry, a one-stop production hub creating borderless and frictionless DeFi & NFT applications. PolkaSmith will be a reliable platform for early-stage startups to unleash their creativity, experiment with bold new ideas, and hack the proliferative growth.",
          "Description (zh)": "PolkaSmith在Kusama Network上实施，是PolkaFoundry的一个金丝雀链，PolkaFoundry是一站式生产中心，创建无边界且无摩擦的DeFi和NFT应用程序。 PolkaSmith是一个可靠的平台，将为初创公司提供一个释放他们的创造力、尝试大胆的新想法并激发增生性增长",
          "Parachain Crowdloans Allocation (en)": "https://medium.com/polkafoundry/jun-14-official-update-about-polkasmith-and-parachain-auction-plan-on-kusama-network-f29c00e1e466",
          "Parachain Crowdloans Allocation (zh)": "https://medium.com/polkafoundry/jun-14-official-update-about-polkasmith-and-parachain-auction-plan-on-kusama-network-f29c00e1e466",
          "Auction Reward & Reward Vesting Schedule (en)": "",
          "Auction Reward & Reward Vesting Schedule (zh)": "",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://polkasmith.polkafoundry.com",
          "Twitter Link": "https://twitter.com/PolkaFoundry",
          "Telegram Link": "https://t.me/PolkaFoundryANN",
          "Discord Link": "",
          "Medium Link": "https://medium.com/polkafoundry",
          "Github Link": "https://github.com/polkafoundry",
        },
        {
          info: 'Quartz',
          paraId: 2095,
          text: 'Quartz by Unique',
          logo: quartz_icon,
          pr: "https://github.com/itering/parachain-projects-info/pull/21",
          "Description (en)": "NFT Chain for Kusama. Provides basic and advanced functionality of creating and managing NFTs in the Kusama ecosystem.",
          "Description (zh)": "Kusama 上的 NFT 链。提供在 Kusama 生态系统中创建和管理 NFT 的基本和高级功能。",
          "Parachain Crowdloans Allocation (en)": "https://unique.network/quartz/crowdloan/contribute/",
          "Parachain Crowdloans Allocation (zh)": "https://unique.network/quartz/crowdloan/contribute/",
          "Auction Reward & Reward Vesting Schedule (en)": "https://unique.network/quartz/crowdloan/contribute/",
          "Auction Reward & Reward Vesting Schedule (zh)": "https://unique.network/quartz/crowdloan/contribute/",
          "Financing Information (en)": "https://unique.network/quartz/",
          "Financing Information (zh)": "https://unique.network/quartz/",
          "Website Link": "https://unique.network/",
          "Twitter Link": "https://twitter.com/unique_nftchain",
          "Telegram Link": "https://t.me/Uniquechain",
          "Discord Link": "https://discord.gg/jHVdZhsakC",
          "Medium Link": "",
          "Github Link": "https://github.com/UniqueNetwork",
          "Owner account": "HC7FK5TNgUrdA8L3P3g4tU6s5axJpjsXr7oYArejv6Vjadh"
        },
        {
          info: 'robonomics',
          paraId: 2077,
          text: 'Robonomics',
          logo: robonomics_icon,
          subscan: "https://robonomics.subscan.io/",
          pr: "https://github.com/itering/parachain-projects-info/pull/12",
          "Description (en)": "Futuristic, secure, and server-less IoT platform on top of Ethereum && Polkadot. Bringing economy of robots into the 4th industrial revolution.",
          "Parachain Crowdloans Allocation (en)": "https://robonomics.network/kusama-slot",
          "Auction Reward & Reward Vesting Schedule (en)": "https://robonomics.network/blog/crowdloan-starts-today",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://robonomics.network/",
          "Twitter Link": "https://twitter.com/AIRA_Robonomics",
          "Telegram Link": "https://t.me/robonomics",
          "Discord Link": "https://discord.com/invite/TM4ABZcGPr",
          "Medium Link": "https://robonomics.network/blog",
          "Github Link": "https://github.com/airalab/robonomics",
        },
        {
          info: 'sakura',
          paraId: 2016,
          text: 'Sakura',
          logo: sakura_icon,
          pr: "https://github.com/itering/parachain-projects-info/pull/3",
          "Description (en)": "Sakura is the sister network of Clover，and it is a substrate-based parachain candidate specifically built for the cross-chain DeFi ecosystem on Kusama. Sakura will utilize all of the core underlying technology stack that Clover has created and is continuously innovating. Developers can migrate their Solidity smart contracts to Sakura seamlessly, while embracing the benefits of Sakura's unique feeconomics。The Clover extension wallet will natively support Sakura dApps on EVM, polkadot.js based injections, and a native-built SKU<->ETH and SKU<->BSC bridge.  Sakura will ultimately aim to be a fundamental cross-chain DeFi infrastructure with a storage layer, smart contract layer, DeFi protocol layer and eApp layer built on top of Kusama.",
          "Description (zh)": "Sakura是Clover的姊妹网，将来通过集成Kusama平行链，来为用户打造一站式的跨链+DeFi解决方案。Sakura将包含Clover所有最新的技术栈以及不断迭代的创新，开发者可以将他们的智能合约无缝迁移到Sakura，并受益于其特有的经济模型。同时Clover钱包也将天然支持Sakura dApps应用（EVM相关dApps，以及波卡生态dApps），以及SKU到以太坊、到币安智能链的双向跨链。Sakura最终将通过其去中心化存储层，智能合约层，DeFi协议层和eApp层来打造一个Kusama之上的跨链+DeFi的基础设施平台。",
          "Parachain Crowdloans Allocation (en)": "https://projectclover.medium.com/meet-sakura-clovers-sister-network-on-kusama-upcoming-crowdloan-auction-6b8cd7f38afa",
          "Parachain Crowdloans Allocation (zh)": "https://projectclover.medium.com/meet-sakura-clovers-sister-network-on-kusama-upcoming-crowdloan-auction-6b8cd7f38afa",
          "Auction Reward & Reward Vesting Schedule (en)": "https://projectclover.medium.com/sakura-pre-crowdloan-is-live-stake-ksm-and-receive-up-to-20-sku-bonus-6d03f5e5087",
          "Auction Reward & Reward Vesting Schedule (zh)": "https://projectclover.medium.com/sakura-pre-crowdloan-is-live-stake-ksm-and-receive-up-to-20-sku-bonus-6d03f5e5087",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://clover.finance/sakura",
          "Twitter Link": "https://twitter.com/clover_finance/",
          "Telegram Link": "https://t.me/clover_en/",
          "Discord Link": "https://discord.gg/z2egJBsBWx",
          "Medium Link": "https://projectclover.medium.com/",
          "Github Link": "https://github.com/clover-network",
        },
        {
          info: 'sherpax',
          paraId: 2013,
          text: 'SherpaX',
          logo: chainx_icon,
        },
        {
          info: 'shiden',
          paraId: 2007,
          text: 'Shiden',
          logo: shiden_icon,
          subscan: "https://shiden.subscan.io/",
        },
        {
          info: 'subgame',
          paraId: 2018,
          text: 'SubGame Gamma',
          logo: subgame_icon,
          pr: "https://github.com/itering/parachain-projects-info/pull/6",
          "Description (en)": "SubGame is a public chain development team based on the Polkadot Para chain. It hopes to build a public chain with cross-chain interoperability. In addition to creating game applications, it can also build various types of application scenarios to create a common cross-chain industry. The blockchain infrastructure provides unlimited possibilities for distributed mobile applications.",
          "Description (zh)": "SubGame为基于Polkadot平行链的公链开发团队，希望能够建立一个拥有跨链互操作性的公链，除了能够打造游戏应用外，也能够构建出各种类型的应用场景，打造跨链产业共通层级的区块链基础设施，为分布式移动应用提供无限可能性。",
          "Parachain Crowdloans Allocation (en)": "",
          "Parachain Crowdloans Allocation (zh)": "",
          "Auction Reward & Reward Vesting Schedule (en)": "",
          "Auction Reward & Reward Vesting Schedule (zh)": "",
          "Financing Information (en)": "",
          "Financing Information (zh)": "",
          "Website Link": "https://www.subgame.org/",
          "Twitter Link": "",
          "Telegram Link": "",
          "Discord Link": "",
          "Medium Link": "",
          "Github Link": "https://github.com/SubGame-Network",
        },
        {
          info: 'trustbase',
          paraId: 2078,
          text: 'Trustbase',
          logo: trustbase_icon,
        },
      ],
      polkadot: [
        {
          info: 'statemint',
          paraId: 1000,
          logo: statemint_icon,
          text: 'Statemint (Shell)',
        },
        {
          info: 'bifrost',
          paraId: 2001,
          logo: bifrost_icon,
          text: 'Bifrost',
        },
        {
          info: 'crust',
          paraId: 2008,
          logo: crust_icon,
          text: 'Crust',
        },
        {
          info: 'darwinia',
          paraId: 2003,
          logo: darwinia_icon,
          text: 'Darwinia',
          subscan: "https://darwinia.subscan.io/",
        },
        {
          info: 'parallel',
          paraId: 2012,
          text: 'Parallel',
        }
      ],
    },
    hasSignature: {
      polkadot: ['2004']
    },
    referralLinks: {
      polkadot: {
        //darwinia
        '2003': 'aa8a67000d39e4f9e31adbd93507ef424d85cf51ec58a415f329b0691741057e',
        //astar
        '2006': '0xaa8a67000d39e4f9e31adbd93507ef424d85cf51ec58a415f329b0691741057e',
        //parallel
        '2012': 'aa8a67000d39e4f9e31adbd93507ef424d85cf51ec58a415f329b0691741057e',
        //manta
        '2015': '0xaa8a67000d39e4f9e31adbd93507ef424d85cf51ec58a415f329b0691741057e'
      }
    }
  }
}]
